import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src2997050974/src/kinetic-ui/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "toasts"
    }}>{`Toasts`}</h1>
    <p>{`The toast support allows bundle and package developers to give brief feedback on the results of actions or events which have
occurred. There are two types of toasts available to developers: toasts and alerts. Toasts are non-intrusive messages
meant to let the end user know that the event has occurred and isn't typically an actionable event. Toasts dismiss, by
default, in 3 seconds. Alerts are larger and displayed in a more visible location and do not automatically dismiss so that
the user can acknowledge them (by dismissing them). Alerts will dismiss automatically if the user navigates to a different
page.`}</p>
    <p><em parentName="p">{`Toast Example`}</em></p>
    <p><img alt="Toast Example" src={require("./toast-example.png")} /></p>
    <p><em parentName="p">{`Alert Example`}</em></p>
    <p><img alt="Toast Example" src={require("./alert-example.png")} /></p>
    <p>{`The toast system provides several helper functions for displaying these`}</p>
    <h2 {...{
      "id": "toast-initial-setup"
    }}>{`Toast Initial Setup`}</h2>
    <p>{`If you are writing a bundle from scratch you will need to put a `}<inlineCode parentName="p">{`ToastsContainer`}</inlineCode>{` in your root `}<inlineCode parentName="p">{`App`}</inlineCode>{` component:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { ToastsContainer } from '@kineticdata/bundle-common';

export const App = ({ children }) => (
  <>
    <ToastsContainer duration={5000} />
    {children}
  </>
);
`}</code></pre>
    <p><strong parentName="p">{`Note`}</strong>{`: The bundles provided by Kinetic Data already have this step done.`}</p>
    <h2 {...{
      "id": "using-toasts"
    }}>{`Using Toasts`}</h2>
    <p>{`Getting a toast or alert to display is fairly trivial. The `}<inlineCode parentName="p">{`addToast`}</inlineCode>{` function displays toasts, and the `}<inlineCode parentName="p">{`addToastAlert`}</inlineCode>{` function
displays alerts.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`addToast`}</inlineCode>{` function can either take a text string or an options object. The options object allows you to configure the
`}<inlineCode parentName="p">{`message`}</inlineCode>{` and `}<inlineCode parentName="p">{`severity`}</inlineCode>{` of the toast. When using a string the severity is always "success". Valid severities are:`}</p>
    <ul>
      <li parentName="ul">{`success`}</li>
      <li parentName="ul">{`info`}</li>
      <li parentName="ul">{`warning`}</li>
      <li parentName="ul">{`danger`}</li>
      <li parentName="ul">{`normal`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { addToast, addToastAlert } from '@kineticdata/bundle-common';

addToast('This operation was successful.');
addToast({ message: 'This operation failed', severity: 'danger' });
addToastAlert('This operation was successful.');
addToastAlert({ title: 'Op Failed', message: 'This operation failed', severity: 'danger' });
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      